export const environment = {
  name: 'klara-dev',
  production: false,
  firebase: {
    apiKey: 'AIzaSyCoq3SJWAYjP7Onl91NFZ5cByZ2ScFk_cA',
    authDomain: 'klara-epost-dev-doc2epost.firebaseapp.com',
    projectId: 'klara-epost-dev-doc2epost',
    storageBucket: 'klara-epost-dev-doc2epost.appspot.com',
    messagingSenderId: '1088480628726',
    appId: '1:1088480628726:web:8b335f5e521f448c3d41d7',
    measurementId: 'G-0H8KTC0LPS',
  },
  keycloak: {
    domain: 'login-dev.klara.tech',
    realmName: 'klara',
    clientId: 'smartsend',
    clientSecret: 'ffa620fa-a07c-4b3e-90ca-b50292c0d9d5',
    redirectUri: 'https://smartsend-dev.epost.dev',
  },
  ePostUrl: 'https://dev.klara-epost.tech'
};
